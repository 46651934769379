/* eslint-disable import/prefer-default-export */
import { graphql, useStaticQuery } from 'gatsby';

export const useSiteMetadata = (): {
    emailSubscriptionApi: string;
    bouyguesApi: string;
} => {
  const { site } = useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          siteMetadata {
            emailSubscriptionApi
            bouyguesApi
          }
        }
      }
    `,
  );
  return site.siteMetadata;
};
