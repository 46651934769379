import React, { useCallback, useState } from 'react';
import { navigate } from 'gatsby';
import { FormattedMessage } from 'react-intl';

import { debounce } from '@onoff/utils';

import { useSiteMetadata } from 'helpers/hooks';

import emailSubscriptionService from 'services/emailSubscriptionService';

import Button from 'components/UI/NewButton';

import styles from './Unsubscribe.module.scss';

interface UnsubscribeProps {
  token: string;
}

const Unsubscribe: React.FC<UnsubscribeProps> = ({
  token,
}) => {
  const { emailSubscriptionApi } = useSiteMetadata();
  const [hasError, setHasError] = useState(false);
  const [step, setStep] = useState(1);

  const unsubscribeUser = useCallback(debounce(async () => {
    await emailSubscriptionService(emailSubscriptionApi).unsubscribe(token).catch(() => {
      setHasError(true);
    });
    setStep(2);

    setTimeout(() => {
      navigate('/');
    }, 7000);
  }), [token]);

  return (
    <div className={styles.container}>
      <h2><FormattedMessage id="components.unsubscribe.unsubscribe" /></h2>
      {step === 1 && (
        <Button
          onClick={unsubscribeUser}
          disabled={!token}
        >
          <FormattedMessage id="components.unsubscribe.unsubscribe" />
        </Button>
      )}

      {step === 2 && (
        <>
          {!hasError && (
            <div><FormattedMessage id="components.unsubscribe.youHaveUnsubscribed" /></div>
          )}

          {hasError && (
            <div><FormattedMessage id="components.unsubscribe.failedToUnsubscribe" /></div>
          )}
        </>
      )}
    </div>
  );
};

export default Unsubscribe;
