import {
  UnsubscribeRequest,
  EmailSubscriptionService,
} from 'types';

import api from 'helpers/server/emailSubscriptionApi';

const emailSubscriptionService: EmailSubscriptionService = (apiPath) => ({
  unsubscribe: (token) => api.post<void, UnsubscribeRequest>(`${apiPath}/email-unsubscribe`, { token }),
});

export default emailSubscriptionService;
