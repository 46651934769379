import React, { useEffect, useState } from 'react';
import { PageProps } from 'gatsby';

import Index, { IndexProps } from 'components/Layout';
import SEO from 'components/seo';
import PageWrapper from 'components/PageWrapper';
import Unsubscribe from 'components/Unsubscribe';

const UnsubscribePage: React.FC<PageProps & IndexProps> = ({ location, pageContext }) => {
  const [token, setToken] = useState('');

  useEffect(() => {
    if (token.length === 0 && location.search) {
      const tokenCandidate = location.search.split('=', 2)[1];
      setToken(tokenCandidate);
    }
  }, [location, token.length]);

  return (
    <Index pageContext={pageContext}>
      <SEO pageTitleTermPath="unsubscribe" />
      <PageWrapper withBottomBackground>
        <Unsubscribe
          token={token}
        />

      </PageWrapper>
    </Index>
  );
};

export default UnsubscribePage;
