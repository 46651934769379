/* eslint-disable no-underscore-dangle,max-len */
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import C from 'helpers/constants/api';
import { ApplicationError } from 'types';

export interface SuccessfulResponse<T> {
    body: T;
}

export interface FailedResponse<APIError> {
    code: APIError;
    description: string;
}

export interface ApiInterface {
    post: <RES, REQ = unknown>(url: string, data: REQ)
        => Promise<RES>;
    get: <RES, REQ = unknown>(url: string, params: REQ)
        => Promise<RES>;
}

class Api<APIError> implements ApiInterface {
    private readonly makeHeaders = (): AxiosRequestConfig['headers'] => ({
      // Don't send headers unless the value is present
      'Content-Type': 'application/json',
    });

    private readonly handleCatch = (error: AxiosError<FailedResponse<APIError>>): APIError => {
      if (error.response) {
        if (error.response.status === 401) {
          // if unauthorized (token is invalid)
          return {
            code: error.response.data.code,
            message: C.ERROR_CODES.UNAUTHORIZED,
          } as unknown as APIError;
        }

        if (error.response.status !== 209) {
          // if any error apart from 209, show a generic error
          return {
            code: error.response.data.code,
            message: C.ERROR_CODES.INTERNAL_ERROR,
          } as unknown as APIError;
        }

        if (error.response.status === 209) {
          // if any error apart from 209, show a generic error
          return {
            code: error.response.data.code,
            message: error.response.data.description,
          } as unknown as APIError;
        }
      }

      if (error.request) {
        // if didn't get a response (network issues)
        return {
          code: error.request.data.code,
          message: C.ERROR_CODES.INTERNAL_ERROR,
        } as unknown as APIError;
      }

      return {
        code: error.request.data.code,
        message: C.ERROR_CODES.INTERNAL_ERROR,
      } as unknown as APIError;
    };

    private readonly request = <RES, REQ>(
        axiosConfig: AxiosRequestConfig,
    ): Promise<RES> => axios({
      headers: this.makeHeaders(),
      withCredentials: false,
      validateStatus: (status) => status === 200, // 200 & 209 is only successful response
      ...axiosConfig,
    })
      .then((axiosResponse: AxiosResponse<RES>) => axiosResponse.data)
      .catch((error: AxiosError<FailedResponse<APIError>>) => Promise.reject(this.handleCatch(error)));

    public post = <RES, REQ = unknown>(
        url: string,
        data: REQ,
    ): Promise<RES> => this.request<RES, REQ>({
      url,
      data,
      method: 'post',
    });

    public get = <RES, REQ = unknown>(
        url: string,
        params: REQ,
    ): Promise<RES> => this.request<RES, REQ>({
      url,
      params,
      method: 'get',
    });
}

export default new Api<ApplicationError>();
