export default {
  ERROR_CODES: {
    INTERNAL_ERROR: 'internal.error',
    UNAUTHORIZED: 'unauthorized',
    EMAIL_CHECK_NOT_EXISTS: 'email-check.not-exists',
    B2B_USER_NOT_ALLOWED: 'b2b-user-not-allowed',
    USER_BLOCKED: 'user-blocked',
    CHECKSUM_INVALID: 'checksum.invalid',
    BYTEL_OFFER_USED: 'bytel.offer-already-used',
  },
  G_RECAPTCHA_PUBLIC_KEY: '6LeiO-AUAAAAAGUn4n08m6C9T7CtyWvNaAPW9Z4I',
};
