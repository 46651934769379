/* eslint-disable */
import classNames from 'classnames';
import React from 'react';

import styles from './Button.module.scss';

export type ButtonProject = 'b2b' | 'b2c';
export type ButtonAppearance = 'primary' | 'secondary' | 'destructive' | 'tertiary';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  appearance?: ButtonAppearance;
  tertiary?: boolean;
  project?: ButtonProject;
  icon?: React.ReactNode;
}

const Button: React.FC<ButtonProps> = ({ // TODO: MOVE to DESIGN SYSTEM
  children,
  appearance = 'primary',
  tertiary = false,
  project = 'b2c',
  className = '',
  icon = null,
  onClick = (): void => { /* do nothing */ },
  ...props
}) => (
  <button
    type="button"
    data-testid="button"
    className={classNames(styles.btn, styles[appearance], styles[project], { [styles.tertiary]: tertiary }, className)}
    onClick={(e): void => onClick(e)}
    {...props}
  >
    <span className={styles.content}>
      {icon ? <span className={styles.icon} aria-hidden>{icon}</span> : null}
      {children}
    </span>
  </button>
);

export default Button;
